<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div
        class="card-header flex-nowrap border-0 pt-6 pb-0"
        style="border: 2px solid red"
      >
        <div class="card-title">
          <div class="card-label">
            <h3>
              Visualización de estados de asistencia
              <span class="d-block text-muted pt-2 font-size-sm"
                >Listado de registros de asistencia por fecha</span
              >
            </h3>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->
      <div class="card-body px-1 px-sm-5 py-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-0 mb-5 rounded-lg" outlined>
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row
                    ><v-col>
                      <p class="ma-0 pa-0 text-body-1">
                        <strong>
                          Debe seleccionar un día, luego un estado de
                          asistencia.
                        </strong>
                      </p>
                    </v-col></v-row
                  >
                  <v-row class="align-items-center mb-n5 mt-n5">
                    <!-- begin::select date filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <v-dialog
                        ref="arrivalDayPickerModalState"
                        v-model="arrivalDayPickerModalState"
                        :return-value.sync="payload.date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <form-group
                            name="día a consultar"
                            :validator="$v.payload.date"
                          >
                            <v-text-field
                              class="mt-7"
                              v-model="payload.date"
                              label="Día a consultar"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              rounded
                              filled
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </form-group>
                        </template>

                        <v-date-picker
                          v-model="payload.date"
                          :max="today"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="arrivalDayPickerModalState = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.arrivalDayPickerModalState.save(
                                payload.date
                              ),
                                resetSelectedAttendanceStatus()
                            "
                          >
                            seleccionar
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <!-- end::select date filter-->

                    <!-- begin::select attendance status filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <!-- begin:: academicGroup combo -->
                      <v-select
                        :items="attendanceStatuses"
                        :loading="isLoadingAttendanceStatus"
                        :disabled="
                          isLoadingAttendanceStatus ||
                          payload.date == '' ||
                          payload.date == null ||
                          payload.date == undefined
                        "
                        item-text="name"
                        item-value="id"
                        v-model="payload.attendance_status"
                        hide-details
                        label="Tipo de asistencia"
                        rounded
                        filled
                        @change="getAttendanceRecords()"
                      >
                        <!-- begin::selected academicGroup item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            class="mb-2 mt-3"
                            :color="`${
                              setAttendanceStatusTheme(item.id).color
                            } lighten-5`"
                            small
                            v-if="index === 0"
                          >
                            <span
                              :class="`${
                                setAttendanceStatusTheme(item.id).color
                              }--text text--darken-1 font-weight-bold`"
                              >{{
                                formattedAttendanceStatus(item.id).name
                              }}</span
                            >
                          </v-chip>
                        </template>
                        <!-- end::selected academicGroup item -->
                      </v-select>
                      <!-- end:: academicGroup combo -->
                    </v-col>
                    <!-- end::select attendance status filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->
            </v-col>
          </v-row>

          <!-- Collapsibles that contains student pictures by grade groups -->

          <v-row>
            <!-- begin::academic group expandable -->
            <v-col cols="12">
              <!-- begin::fallback if academic group is empty -->
              <div v-if="payload.attendance_status !== ''">
                <v-card
                  v-if="attendanceRecords.data.length > 0"
                  class="elevation-0 mb-5"
                >
                  <p class="font-weight-medium text-body-1">
                    Haga click sobre el grado que desea consultar para desplegar
                    el contenedor.
                  </p>
                  <template>
                    <v-expansion-panels focusable>
                      <v-expansion-panel
                        v-for="(record, index) in attendanceRecords.data"
                        :key="index"
                      >
                        <v-expansion-panel-header>
                          <p
                            class="text-h6 font-weight-bold blue-grey--text text--darken-2 mb-0"
                          >
                            {{ record.grade }}
                          </p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            class="pl-4 mt-4"
                            v-for="(student, indexStudents) in record.data"
                            :key="indexStudents"
                          >
                            <v-row>
                              <v-col cols="12" xl="10">
                                <div
                                  class="d-flex flex-row justify-start align-center mb-5"
                                >
                                  <div>
                                    <v-avatar
                                      size="80"
                                      class="mr-3 grey lighten-4"
                                    >
                                      <v-img
                                        :src="student.photo"
                                        :alt="student.first_name"
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                  <div
                                    class="d-flex flex-column justify-center"
                                  >
                                    <p class="text-h6 font-weight-bold mb-0">
                                      {{ student.code }}
                                    </p>
                                    <p class="text-h6 font-weight-medium mb-6">
                                      {{ student.first_name }}
                                      {{ student.last_name }}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <p class="text-h6 font-weight-medium mb-4">
                                    Registros de estado seleccionado
                                    <span class="font-weight-bold"
                                      >"{{
                                        selectedAttendanceStatus.statusName
                                      }}"</span
                                    >
                                  </p>
                                  <v-timeline align-top dense>
                                    <v-timeline-item
                                      v-for="(
                                        attendanceRecord, attRecordIndex
                                      ) in student.attendances"
                                      :key="attRecordIndex"
                                      :color="`${
                                        attendanceRecord.is_justified
                                          ? 'success'
                                          : 'primary'
                                      }`"
                                    >
                                      <v-card
                                        class="grey lighten-5 pa-5 elevation-1 rounded-lg"
                                      >
                                        <p class="font-weight-bold mb-0">
                                          {{ attendanceRecord.subject }}
                                          <v-icon
                                            v-if="attendanceRecord.is_justified"
                                            >mdi-circle-small</v-icon
                                          >

                                          <v-chip
                                            v-if="attendanceRecord.is_justified"
                                            small
                                            :color="`primary lighten-5`"
                                            ><span
                                              class="font-weight-bold primary--text"
                                              >Justificado
                                              <v-icon x-small
                                                >mdi-check-circle</v-icon
                                              ></span
                                            ></v-chip
                                          >
                                          <v-icon
                                            v-if="attendanceRecord.is_justified"
                                            >mdi-circle-small</v-icon
                                          >
                                          <v-chip
                                            v-if="attendanceRecord.is_justified"
                                            small
                                            class="ml-1"
                                            :color="`orange lighten-5`"
                                            ><span
                                              class="font-weight-bold orange--text"
                                            >
                                              {{
                                                attendanceRecord.justified_motive
                                              }}
                                            </span></v-chip
                                          >
                                        </p>
                                        <p class="font-weight-medium mb-0">
                                          Entre
                                          {{ attendanceRecord.start_time }} y
                                          {{ attendanceRecord.end_time }}
                                        </p>
                                      </v-card>
                                    </v-timeline-item>
                                  </v-timeline>
                                </div>
                              </v-col>
                            </v-row>

                            <v-divider class="mt-0"></v-divider>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </v-card>

                <v-card class="elevation-0" v-else>
                  <v-row>
                    <v-col
                      class="mx-auto d-flex flex-column"
                      cols="12"
                      sm="8"
                      md="6"
                      lg="4"
                    >
                      <div
                        class="mx-auto mb-4 pa-8 rounded-circle red lighten-4"
                      >
                        <v-icon large color="red"
                          >mdi-account-off-outline</v-icon
                        >
                      </div>
                      <div>
                        <p class="text-center font-weight-bold text-h6 mb-1">
                          No se encontraron registros
                        </p>
                        <p class="text-center font-weight-medium text-body-1">
                          no hay registros del estado seleccionado en la fecha
                          seleccionada.
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div v-else>
                <v-row>
                  <v-col
                    class="mx-auto mb-15 mt-10 d-flex flex-column"
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
                      <v-icon large color="red">mdi-account-off-outline</v-icon>
                    </div>
                    <div>
                      <p class="text-center font-weight-bold text-h6 mb-1">
                        Debe seleccionar una fecha y un estado de asistencia.
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!-- end::fallback if academic group is empty -->
            </v-col>
            <!-- end::academic group expandable -->
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import gradeRepository from "@/repositories/gradeRepository";
import groupRepository from "@/repositories/groupRepository";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import attendanceStatusRepository from "@/repositories/attendanceStatusRepository";
import studentClassRepository from "../../../repositories/studentClassRepository";
import { mapGetters } from "vuex";

export default {
  name: "StudentsAbsencesReport",
  title: "Estados de asistencia | GE ITR",

  mounted() {
    this.getAttendanceStatus();
  },

  data() {
    return {
      payload: {
        date: "",
        attendance_status: "",
      },
      arrivalDayPickerModalState: false,
      isLoadingAttendanceStatus: false,
      attendanceStatuses: [],
      attendanceRecords: {
        data: [],
        isLoading: false,
      },

      //---------------------
      grades: [],
      academicGroups: [],
      technicalGroups: [],
      gradeSectionGroups: [],
      gradeSpecialityGroups: [],
      areGradesLoading: false,
      areAcademicGroupsLoading: false,
      isLoadingAcademicGroupStudents: false,
      isLoadingTechnicalGroupStudents: false,
      areTechnicalGroupsLoading: false,
      TERCER_CICLO_ID: 1,
      BACHILLERATO_ID: 2,
      report: {
        params: {},
      },
      areStudentsLoading: false,
      sectionGroupColors: {
        "A-1": "light-blue ",
        "A-2": "light-blue darken-1",
        "A-3": "light-blue darken-2",
        "A-4": "light-blue darken-3",
        "A-5": "light-blue darken-4",
        "B-1": "teal",
        "B-2": "teal darken-1",
        "B-3": "teal darken-2",
        "B-4": "teal darken-3",
        "B-5": "teal darken-4",
        "C-1": "green darken-2",
        "D-1": "purple darken-2",
        "E-1": "indigo darken-4",
      },
    };
  },

  methods: {
    resetReportParams() {
      this.report.params = {};
      (this.gradeSpecialityGroups = []), (this.gradeSectionGroups = []);
    },

    goBack() {
      this.$router.go(-1);
    },

    resetSelectedAttendanceStatus() {
      this.payload.attendance_status = "";
      this.attendanceRecords.data = [];
    },

    // ------------------- fetching data --------------------------
    getAttendanceStatus() {
      this.isLoadingAttendanceStatus = true;
      attendanceStatusRepository
        .getAllAttendanceStatus()
        .then((data) => {
          this.attendanceStatuses = [...data.data.data];
          let statuses = [...data.data.data];

          for (let itemName in this.notValidAttendaceStatus) {
            if (this.notValidAttendaceStatus[itemName]) {
              let indexToRemove = statuses.findIndex(
                (obj) => obj.name === itemName
              );
              if (indexToRemove !== -1) {
                statuses.splice(indexToRemove, 1);
              }
            }
          }

          this.attendanceStatusesToChoose = statuses;
        })
        .catch()
        .finally(() => {
          this.isLoadingAttendanceStatus = false;
        });
    },

    //fetch all data from params
    getAttendanceRecords() {
      this.isLoadingAttendanceStatus = true;
      studentClassRepository
        .getAttendanceRecordsByDate(
          this.payload.date,
          this.payload.attendance_status
        )
        .then((data) => {
          this.attendanceRecords.data = data.data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isLoadingAttendanceStatus = false;
        });
    },

    setAttendanceStatusTheme(attendaceStatus) {
      //evaluating attendance status ID's
      let attendanceStatusSchemas = {
        1: {
          color: "green",
        },
        2: {
          color: "red",
        },
        3: {
          color: "orange",
        },
        4: {
          color: "blue-grey",
        },
        5: {
          color: "purple",
        },
      };

      let defaultSchema = {
        color: "grey",
      };

      if (attendanceStatusSchemas.hasOwnProperty(attendaceStatus)) {
        return attendanceStatusSchemas[attendaceStatus];
      } else {
        return defaultSchema;
      }
    },

    formattedAttendanceStatus(id) {
      let foundStatus = this.attendanceStatuses.find((status) => {
        return status.id === id;
      });
      return foundStatus;
    },

    loadGrades() {
      this.areGradesLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areGradesLoading = false;
        });
    },

    loadAcademicGroups() {
      //reseting groups
      this.gradeSectionGroups = [];
      this.areAcademicGroupsLoading = true;
      academicGroupRepository
        .getAcademicGroupsByGrade(this.report.params.grade_id)
        .then(({ data }) => {
          this.academicGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areAcademicGroupsLoading = false;
        });
    },

    loadTechnicalGroups() {
      //reseting groups
      this.gradeSpecialityGroups = [];
      this.areTechnicalGroupsLoading = true;
      technicalGroupRepository
        .getTechnicalGroupsByGrade(this.report.params.grade_id)
        .then(({ data }) => {
          this.technicalGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areTechnicalGroupsLoading = false;
        });
    },

    loadAcademicGroupsStudentProfilePictures() {
      this.isLoadingAcademicGroupStudents = true;
      groupRepository
        .getStudentsProfilePicturesByAcademicGroup(
          this.report.params?.grade_section_group?.id
        )
        .then(({ data }) => {
          this.gradeSectionGroups = data;
        })
        .catch((err) => {
          console.warn(err);
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isLoadingAcademicGroupStudents = false;
        });
    },

    loadTechnicalGroupsStudentProfilePictures() {
      this.isLoadingTechnicalGroupStudents = true;
      groupRepository
        .getStudentsProfilePicturesByTechnicalGroup(
          this.report.params?.grade_speciality_group?.id
        )
        .then(({ data }) => {
          this.gradeSpecialityGroups = data;
        })
        .catch((err) => {
          console.warn(err);
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isLoadingTechnicalGroupStudents = false;
        });
    },

    // -------------------- Flow management and validations ----------------------

    loadSelectedGroup() {
      if (this.isEmptySelection()) {
        return;
      }

      if (this.academicLevelId == this.TERCER_CICLO_ID) {
        return this.loadAcademicGroupsStudentProfilePictures();
      }

      if (this.academicLevelId == this.BACHILLERATO_ID) {
        return this.loadTechnicalGroupsStudentProfilePictures();
      }
    },

    grade_tercerCiclo(group) {
      return group.gradeSpecialityGroup?.grades?.name;
    },

    grade_bachillerato(group) {
      return group.gradeSectionGroup?.grades?.name;
    },

    isEmptySelection() {
      return (
        !!"grade_section_group" in this.report.params ||
        !!"grade_speciality_group" in this.report.params
      );
    },

    specialitySectionGroup(group) {
      return `${group.gradeSectionGroup.section_group?.section?.name}-${group.gradeSectionGroup.section_group?.group?.name}`;
    },
  },

  computed: {
    selectedAttendanceStatus() {
      return {
        statusName: this.formattedAttendanceStatus(
          this.payload.attendance_status
        ).name,
        statusTheme: this.setAttendanceStatusTheme(
          this.payload.attendance_status
        ).color,
      };
    },
    today() {
      let date = new Date();
      //includes time
      return date.toISOString();
    },
    filterAllowedDates(date) {
      // Checking date is a JavaScript Date object
      if (!(date instanceof Date)) {
        date = new Date(date);
      }

      // Getting the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = date.getDay();

      // Checking if the day of the week matches the selected day
      return dayOfWeek === this.selectedSchedule.day.id - 1;
    },

    canShowStudents() {
      return (
        this.report.params.grade_section_group != null ||
        this.report.params.grade_section_group != undefined ||
        this.report.params.grade_speciality_group != null ||
        this.report.params.grade_speciality_group != undefined
      );
    },
    sectionGroup() {
      return this.report.params.grade_section_group
        ?.grade_section_group_formatted;
    },
    specialityGroup() {
      return this.report.params.grade_speciality_group
        ?.grade_speciality_group_formatted;
    },
    academicLevelId() {
      if (!("grade_id" in this.report.params)) {
        return;
      }

      const academicLevel = this.grades.find(
        (item) => item.id == this.report.params.grade_id
      );
      return academicLevel.academic_level.id;
    },

    ...mapGetters(["currentPageActions"]),
  },

  validations: {
    payload: {},
  },
};
</script>
