import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "attendance-status";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllAttendanceStatus = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

export default {
  getAllAttendanceStatus,
};
